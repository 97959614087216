import { mapActions, mapGetters, mapMutations } from "vuex"
import { getHeadersForTable } from "@/utils/table"
import {
  TABLE_NAMES,
  FIELD_TYPES,
  MAX_CHARACTER_LIMIT,
  ALLOWED_TYPES_FOR_REPORTER_INTAKE_FORMS
} from "@/constants"
import { REPORTER_INTAKE_FORM } from "@/constants/bread-crumbs/reporter-intake-form"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

export default {
  name      : "ReporterIntakeForm",
  components: {
    ThemisDecision,
    ThemisInput
  },
  data() {
    return {
      showAddIntakeFormFieldsDialog  : false,
      fieldsToBeAdded                : [],
      showFormTemplateInput          : false,
      localFormTemplate              : undefined,
      isFormTemplateNameInputFocussed: false,
      isUpdatedNameDuplicated        : false,
      fieldsAreDraggable             : false,
      draggingFormTemplateField      : undefined,
      dragEnterFormTemplateField     : undefined,
      formTemplateNameCharacterLimit : MAX_CHARACTER_LIMIT.REPORTER_FORM_TEMPLATE_NAME
    }
  },
  computed: {
    ...mapGetters({
      formTemplates                     : "formTemplates/formTemplates",
      isLoadingFormTemplates            : "formTemplates/isLoadingFormTemplates",
      fieldsV2                          : "fields/fieldsV2",
      isLoadingFields                   : "fields/isLoadingFieldsV2",
      optionLists                       : "optionLists/optionLists",
      isLoadingOptionLists              : "optionLists/isLoadingOptionLists",
      formTemplateConfigurations        : "formTemplateConfigurations/formTemplateConfigurations",
      isAddingFormTemplateConfigurations: "formTemplateConfigurations/isAddingFormTemplateConfigurations",
      formTemplateConfigurationsAdded   : "formTemplateConfigurations/formTemplateConfigurationsAdded",
      isUpdatingFormTemplateName        : "formTemplates/isUpdatingFormTemplateName",
      isFormTemplateNameUpdated         : "formTemplates/isFormTemplateNameUpdated",
      formTemplateNameUpdateError       : "formTemplates/formTemplateNameUpdateError",
      isUpdatingSortingOrder            : "formTemplateConfigurations/isUpdatingSortingOrder",
      isSortingOrderUpdated             : "formTemplateConfigurations/isSortingOrderUpdated"
    }),
    headersForFieldsOverviewTable() {
      return getHeadersForTable(TABLE_NAMES.FORM_TEMPLATE_FIELDS, this.$t.bind(this))
    },
    headersForFieldsAddTable() {
      return getHeadersForTable(TABLE_NAMES.FORM_TEMPLATE_ADD_FIELDS, this.$t.bind(this))
    },
    formTemplateFieldsTableDetailsLoading() {
      return this.isLoadingFormTemplates ||
        this.isLoadingFields ||
        this.isLoadingOptionLists ||
        this.isUpdatingSortingOrder
    },
    formTemplateFieldsForOverviewTable() {
      return this.formTemplateConfigurations.filter(formTemplateConfiguration =>
        formTemplateConfiguration.formTemplateId === this.formTemplateId
      ).sort((firstFormTemplateConfiguration, secondFormTemplateConfigurations) =>
        firstFormTemplateConfiguration.sortingOrder - secondFormTemplateConfigurations.sortingOrder
      ).map(formTemplateConfiguration => {
        const field = this.fieldsMap[formTemplateConfiguration.fieldId]
        let fieldId
        let fieldSystemName
        let fieldType
        let fieldTypeIcon
        if (field) {
          ({ fieldType, fieldTypeIcon } = this.getFieldTypeAndIcon(field))
          fieldId                      = field.id
          fieldSystemName              = field.systemName
        }
        return {
          id          : formTemplateConfiguration.id,
          sortingOrder: formTemplateConfiguration.sortingOrder,
          fieldId,
          fieldSystemName,
          fieldType,
          fieldTypeIcon
        }
      })
    },
    formTemplateFieldIds() {
      return this.formTemplateFieldsForOverviewTable.map(formTemplateField =>
        formTemplateField.fieldId
      )
    },
    fieldsForFormTemplateFieldsAddTable() {
      return this.fieldsV2.filter(field =>
        !this.formTemplateFieldIds.includes(field.id) &&
        ALLOWED_TYPES_FOR_REPORTER_INTAKE_FORMS.includes(field.type)
      ).map(field => {
        return {
          id        : field.id,
          systemName: field.systemName,
          ...this.getFieldTypeAndIcon(field)
        }
      })
    },
    formTemplateId() {
      return +this.$route.params.id
    },
    formTemplate() {
      return this.formTemplates?.find(formTemplate => formTemplate.id === this.formTemplateId)
    },
    isFormTemplateNameDuplicate() {
      return this.formTemplateNameUpdateError?.type === "duplicate"
    },
    isFormTemplateNameChanged() {
      return this.formTemplate?.name !== this.localFormTemplate?.name
    },
    optionListsMap() {
      const optionListsMap = new Object()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    fieldsMap() {
      const fieldsMap = new Object()
      for (const field of this.fieldsV2) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    calculateHeightForAddTable() {
      const dataRowHeight      = 48
      const maxAvailableHeight = window.innerHeight - 430
      const maxDataRows        = Math.floor((maxAvailableHeight / dataRowHeight) - 1)
      let heightOfTable        = dataRowHeight // initialize with height for header

      if (this.fieldsForFormTemplateFieldsAddTable.length > maxDataRows) {
        heightOfTable += maxDataRows * dataRowHeight
      } else if (!this.fieldsForFormTemplateFieldsAddTable.length) {
        heightOfTable += dataRowHeight // one row for "no data available"
      } else {
        heightOfTable += this.fieldsForFormTemplateFieldsAddTable.length * dataRowHeight
      }

      return heightOfTable
    },
    selectedFormTemplateConfiguration() {
      if (this.$route.params.configurationId) {
        return this.formTemplateConfigurations.find(
          formTemplateConfiguration => formTemplateConfiguration.id === +this.$route.params.configurationId
        )
      }
    },
    selectedField() {
      return this.fieldsMap[this.selectedFormTemplateConfiguration?.fieldId]
    },
    optionListOfSelectedField() {
      return this.optionListsMap[this.selectedField?.optionListId]
    }
  },
  methods: {
    ...mapActions({
      notify                         : "shared/notify",
      addFormTemplateConfigurations  : "formTemplateConfigurations/addFormTemplateConfigurations",
      updateFormTemplateConfiguration: "formTemplateConfigurations/updateFormTemplateConfiguration",
      updateFormTemplate             : "formTemplates/updateFormTemplate"
    }),
    ...mapMutations({
      resetFormTemplateUpdateError: "formTemplates/resetFormTemplateUpdateError",
      setBreadcrumbs              : "shared/setBreadcrumbs",
      setPageTitle                : "shared/setPageTitle"
    }),
    getFieldTypeAndIcon(field) {
      const fieldType = Object.values(FIELD_TYPES).find(type => type.value === field.type)
      return {
        fieldType: this.$t(fieldType?.name, {
          name: this.optionListsMap[field.optionListId] ? `: ${this.optionListsMap[field.optionListId].name}` : ""
        }),
        fieldTypeIcon: fieldType?.icon
      }
    },
    async addFormTemplateConfigurationsHandler() {
      const formTemplateConfigurationsPayload = this.fieldsToBeAdded.map(field => ({
        formTemplateId: this.formTemplateId,
        fieldId       : field.id
      }))
      this.addFormTemplateConfigurations(formTemplateConfigurationsPayload)
    },
    handleClickOnRow(item) {
      if ((this.$route.name === "reporter-intake-form" ||
        this.$route.name === "reporter-intake-form-template-configuration") &&
        +this.$route.params?.configurationId !== item.id) {
        this.$router.push({
          name  : "reporter-intake-form-template-configuration",
          params: {
            id             : this.formTemplateId,
            configurationId: item.id
          }
        })
      }
    },
    getClassForFieldRow(item) {
      let classForFieldRow = ""
      if (item.id === this.selectedFormTemplateConfiguration?.id) {
        classForFieldRow += "blue lighten-5"
      }
      if (item.id === this.dragEnterFormTemplateField?.id && item.id !== this.draggingFormTemplateField.id) {
        classForFieldRow += "drop-row"
      }
      return classForFieldRow
    },
    onFieldDragStart(formTemplateField) {
      this.draggingFormTemplateField  = formTemplateField
      this.dragEnterFormTemplateField = this.draggingFormTemplateField
    },
    onFieldDragEnter(formTemplateField) {
      this.dragEnterFormTemplateField = formTemplateField
    },
    onFieldDragOver(event) {
      event.preventDefault()
    },
    onFieldDragEnd() {
      if (this.draggingFormTemplateField.id !== this.dragEnterFormTemplateField.id) {
        const isFormTemplateFieldDraggedUp =
          !!(this.draggingFormTemplateField.sortingOrder - this.dragEnterFormTemplateField.sortingOrder)

        const sortingOrder = isFormTemplateFieldDraggedUp ?
          this.dragEnterFormTemplateField.sortingOrder : this.dragEnterFormTemplateField.sortingOrder - 1

        this.updateFormTemplateConfiguration({
          id: this.draggingFormTemplateField.id,
          sortingOrder
        })
      }
    },
    handleFormTemplateNameInputOnBlurEvent(onBlur) {
      onBlur()
      this.verifyAndUpdateFormTemplateName()
    },
    handleFormTemplateNameInputOnEnter() {
      this.$refs.input_reporter_intake_form_name.blur()
    },
    verifyAndUpdateFormTemplateName() {
      if (this.isFormTemplateNameChanged) {
        if (this.localFormTemplate.name &&
            this.localFormTemplate.name.length <= this.formTemplateNameCharacterLimit) {
          this.updateFormTemplate({
            id  : this.localFormTemplate.id,
            name: this.localFormTemplate.name
          })
        }
      }
    }
  },
  watch: {
    formTemplate: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localFormTemplate) {
          this.localFormTemplate = { ...newValue }
        }
      }
    },
    "localFormTemplate.name": {
      immediate: false,
      handler  : function() {
        if (this.formTemplateNameUpdateError) {
          this.resetFormTemplateUpdateError(["name"])
        }
      }
    },
    formTemplateConfigurationsAdded: {
      handler: function(value) {
        if (value) {
          this.notify({
            type      : "success",
            text      : "818",
            parameters: {
              formTemplateName: this.formTemplate.name
            }
          })
          this.showAddIntakeFormFieldsDialog = false
        }
      }
    },
    showAddIntakeFormFieldsDialog: {
      handler: function(value) {
        if (!value) {
          this.fieldsToBeAdded = []
        }
      }
    },
    isFormTemplateNameUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.setBreadcrumbs(
            REPORTER_INTAKE_FORM({ params: { id: this.localFormTemplate.id } }, this.localFormTemplate.name
            )
          )
          this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localFormTemplate.name))
        }
      }
    },
    isSortingOrderUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "917",
            parameters: {
              fieldSystemName: this.draggingFormTemplateField.fieldSystemName
            }
          })
          this.draggingFormTemplateField  = undefined
          this.dragEnterFormTemplateField = undefined
        }
      }
    }
  }
}